<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex flex-column justify-content-start">
          <div class="position-relative">
            <div v-if="userData.coverImage" class="d-flex justify-content-center">
              <cld-image
                :publicId="userData.coverImage"
                style="max-width:400px;height: 100%;width: 100%;"
              >
                <cld-transformation width="400" crop="fill" />
              </cld-image>
            </div>
            <b-avatar
              v-else
              :src="userData.avatar"
              :text="avatarText(userData.name)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="104px"
              rounded
              style="width: 100%!important;"
            />
            <div
              class="position-absolute d-inline bg-white"
              style="right: 0px; bottom: 0px;z-index: 2"
            >
              <feather-icon icon="EditIcon" size="26" @click="openUploadModal" />
            </div>
          </div>
          <div class="d-flex flex-column mt-2">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'admin-restaurants-edit', query: { id: userData._id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button variant="outline-danger" class="ml-1">
                Deactivate
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-none d-flexxxxx align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr class="d-none">
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <!--          <tr>-->
          <!--            <th class="pb-50">-->
          <!--              <feather-icon-->
          <!--                icon="StarIcon"-->
          <!--                class="mr-75"-->
          <!--              />-->
          <!--              <span class="font-weight-bold">Role</span>-->
          <!--            </th>-->
          <!--            <td class="pb-50 text-capitalize">-->
          <!--              {{ userData.role }}-->
          <!--            </td>-->
          <!--          </tr>-->
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-50">
              {{
                (userData.address.printable || userData.address.address || '').substring(0, 25)
              }}
            </td>
          </tr>
          <!--          <tr>-->
          <!--            <th class="pb-50">-->
          <!--              <feather-icon-->
          <!--                icon="FlagIcon"-->
          <!--                class="mr-75"-->
          <!--              />-->
          <!--              <span class="font-weight-bold">Country</span>-->
          <!--            </th>-->
          <!--            <td class="pb-50">-->
          <!--              {{ userData.country }}-->
          <!--            </td>-->
          <!--          </tr>-->
          <tr>
            <th class="pb-50">
              <feather-icon icon="ClockIcon" class="mr-75" />
              <span class="font-weight-bold">Timezone</span>
            </th>
            <td class="pb-50">
              {{ userData.timezone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.mobile }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'
import useUsersList from '../restaurants-list/useUsersList'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openUploadModal() {
      try {
        window.cloudinary
          .openUploadWidget(
            {
              cloud_name: 'thecompanion',
              upload_preset: 'vhopflsi',
            },
            (error, result) => {
              if (!error && result && result.event === 'success') {
                store
                  .dispatch('app-user/updateCoverImage', {
                    id: router.currentRoute.query.id,
                    updateCoverImage: {
                      logo: result.info.public_id,
                    },
                  })
                  .then(() => {
                    // TODO show success alert
                  })
                  .catch(() => {
                    // TODO show failure alert
                  })
                this.url = result.info.url
                this.userData.coverImage = result.info.public_id
              }
            }
          )
          .open()
      } catch (e) {
        // TODO show failure alert
      }
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style></style>
