/* eslint-disable no-console */
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-console
        console.info('requesting..', queryParams)
        axios
          .get('restaurants', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`restaurants/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`restaurants/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCoverImage(ctx, { id, updateCoverImage }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`restaurants/${id}/cover-image`, updateCoverImage)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editIntegration(ctx, { id, postData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`restaurants/edit-integrations/${id}`, postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('restaurants', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('restaurants/categories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    updateRestaurantCategories(ctx, { id, updateRestaurantCategoriesDto }) {
      return new Promise((resolve, reject) => {
        console.log(updateRestaurantCategoriesDto)
        axios
          .patch(`restaurants/update-categories/${id}`, updateRestaurantCategoriesDto)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
